
<app-navbar-one></app-navbar-one>

<!-- Start Page Title Area -->
<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/ListNews">News</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>14 countries participate in Indonesia International IoT Olympiad 2022 in Kudus</h1>
            <img class="" src="../../../../assets/img/news/UMK.WebP" alt="">
            <p>
                Kudus (ANTARA) - A total of 172 teams from 14 countries participated in the Indonesia International Internet of Think (IoT) Olympiad 2022, which is a competition for creativity in various fields of science, especially science and technology.
            </p>
            <p>
                "The competition was organized by the Indonesian Young Scientist Association (IYSA) in collaboration with Muria Kudus University (UMK). The implementation is hybrid (online and offline)," said UMK Vice Rector III Sugeng Slamet in Kudus on Monday.
            </p>
            <p>
                According to him, from dozens of countries that took part, among them there were teams from Singapore, Malaysia, Mexico, and the United States "UMK as the host, focuses on organizing the competition for three days from Saturday (21/5) to Monday (23/5) at the UMK Auditorium," he said.The competition, he said, is a competition that opens opportunities for young researchers to show creativity in various fields, especially science and technology.
            </p>
            <p>
                "This competition aims to explore innovation and creativity in the fields of science and technology. The hope is that it can be an encouragement for students because they not only compete at the national level, but also internationally. The jury is also not only local but also some from abroad," he said. UMK has repeatedly won gold medals in the competition, including Bali, Surabaya, and Semarang. President of IYSA Deni Irawan expressed his appreciation and gratitude to MSEs for helping in the success of the Indonesia International IoT Olympiad 2022 event.
            </p>
            <p>
                Competing in more than 10 categories, IoT "events" were also attended by young researchers consisting of elementary schools (SD) to universities. The research categories contested include IoT in business, IoT in education, IoT in healtcare, social science, innovative science. UMK sent five teams to compete in this competition with the hope of winning awards at the peak of the awarding ceremony which was announced on Monday (23/5) at 19.00 WIB.
 
            </p>
            <p>Source : <a target="_blank"
                    href="https://www.antaranews.com/berita/2896941/14-negara-ikuti-indonesia-international-iot-olympiad-2022-di-kudus#:~:text=Universitas%20Muria%20Kudus%20(UMK)%20menjadi,Think%20(IoT)%20Olympiad%202022%20.">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->