
<app-navbar-one></app-navbar-one>

<!-- Start Page Title Area -->
<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/ListNews">News</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>UMK to Host Indonesia International IoT Olympiad 2022</h1>
            <img class="" src="../../../../assets/img/news/UMKtuanrumah.WebP" alt="">
            <p>
                UMK - Muria Kudus University is hosting the Indonesia International IoT Olympiad 2022. This international competition organized by the Indonesian Young Scientist Association (IYSA) collaborated with UMK to succeed the Indonesia International IoT Olympiad (I3O) event which was held on Saturday (21/5/22) at the Auditorium of Muria Kudus University.
            </p>
            <p>
                I3O itself is a competition event that opens opportunities for young researchers to show creativity in various fields, especially science and technology. Lasting three days, the event was held in a hybrid (offline / online) manner, attended by 172 teams from 14 countries ranging from Singapore, Malaysia, to the United States and Mexico.
            </p>
            <p>
                Opening the event, UMK Vice Rector III Dr. Sugeng Slamet, S.T M.T, said, "This I3O competition aims to explore innovation and creativity in the fields of science and technology", he said. The UMK community would also like to thank IYSA for collaborating and succeeding this I3O event and those who have contributed to the event.
            </p>
            <p>
                President of IYSA Deni Irawan, M.Pd expressed his appreciation and gratitude to Muria Kudus University for helping in the success of the I3O 2022 event. "Thank you for IYSA's collaboration with MSEs in contributing and supporting the implementation of I3O", He said. Competing in more than 10 categories, the I3O event was attended by young researchers consisting of elementary schools (SD) to universities. The research categories contested include IoT in Business, IoT in Education, IoT in Healtcare, Social Science, Innovative Science and many more.
            </p>
            <p>
                Muria Kudus University itself sent 5 teams to compete in this I3O competition with the hope of winning awards at the peak of the awarding ceremony which was announced on Monday (23/5/22) at 19.00 WIB. 
            </p>
            <p>Source : <a target="_blank"
                    href="https://isknews.com/diikuti-172-tim-dari-14-negara-umk-jadi-tuan-rumah-indonesia-international-iot-olympiad-2022/">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->