import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jenjangonl24',
  templateUrl: './jenjangonl24.component.html',
  styleUrls: ['./jenjangonl24.component.scss']
})
export class Jenjangonl24Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Implementasi lain yang mungkin Anda miliki di sini
  }
}
