import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kategori23',
  templateUrl: './kategori23.component.html',
  styleUrls: ['./kategori23.component.scss']
})
export class Kategori23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Implementasi lain yang mungkin Anda miliki di sini
  }
}
