
<app-navbar-one></app-navbar-one>

<!-- Start Page Title Area -->
<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/ListNews">News</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>UNSOED Delegation Wins I3O International IoT Olympiad 2023 Medals</h1>
            <img class="" src="../../../../assets/img/news/UNSOED.WebP" alt="">
            <p>
                Another proud achievement was presented by students of Jenderal Soedirman University (UNSOED) at the international level. This time at a prestigious event smelling of industrial revolution 4.0, namely the Indonesia International IoT Olympiad (I3O) 2023. In the event, four Unsoed delegates won 2 gold medals and 2 silver medals.The first gold medal was won by a team that competed offline at Syiah Kuala University (USK), carrying a work with the concept of digital technology with the name Salt.Up included in the IoT category in the business sector.
            </p>
            <p>
                The second gold medal was won by a team consisting of Monica Achir Putri (Agricultural Engineering 2020), Mega Utami (Agricultural Engineering 2020), Desti Eka Safitri (Agricultural Engineering 2021), Yusrotul Mufarohah (Agricultural Engineering 2021), and (Kukuh Adji Ferdinantara, Agricultural Engineering 2020) by carrying out works with the concept of solar energy-based IoT technology in aquaponics under the guidance of Ropiudin, S.TP., M.Si.
            </p>
            <p>
                The first silver medal was won by a team consisting of Dhelya Apriliani Putri (Agricultural Engineering 2021), Cheisya Shafira (Agricultural Engineering 2019), Vina Helmi Nur Istiqomah (Mathematics 2021), Verania Ikke Marshella (Food Technology 2021), and Rio Ferianto (Electrical Engineering 2021) by carrying out solar energy-based IoT work for milk grading under the guidance of Ropiudin, S.TP., M.Si.
            </p>
            <p>
                The second silver medal was won by a team consisting of Adinda Cahya Putri (Agricultural Engineering 2021), Panca Cahya Utami (Agricultural Engineering 2019), Salma Alisfahani Khalisha (Agricultural Engineering 2021), Tijani Salsabila (Agricultural Engineering 2021), and M. Abdan Syakura (Agricultural Engineering 2021) by carrying out solar energy-based IoT innovations for agricultural irrigation control under Ropiudin, S.TP., M.Si.
            </p>
            <p>
                The I3O 2023 competition is a science and innovation competition in the field of IoT (Internet of Things) organized by IYSA (Indonesia Young Scientist Association) which this time was held at Syiah Kuala University (USK), Aceh. Followed by 101 teams from 13 countries.In the competition which took place online, it began with the selection of paper documents on May 19, 2023, then continued with exhibition and judging on May 13-17, 2023 through Zoom Meeting. Meanwhile, the announcement of the results (Awarding) on May 17, 2023

            </p>
            <p>Source : <a target="_blank"
                    href="https://unsoed.ac.id/id/delegasi-unsoed-raih-medali-olimpiade-iot-internasional-i3o-2023">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->