import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jenjangofl24',
  templateUrl: './jenjangofl24.component.html',
  styleUrls: ['./jenjangofl24.component.scss']
})
export class Jenjangofl24Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Implementasi lain yang mungkin Anda miliki di sini
  }
}
