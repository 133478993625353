import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kategori24',
  templateUrl: './kategori24.component.html',
  styleUrls: ['./kategori24.component.scss']
})
export class Kategori24Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Implementasi lain yang mungkin Anda miliki di sini
  }
}
