
<app-navbar-one></app-navbar-one>

<!-- Start Page Title Area -->
<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/ListNews">News</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>Being the First Event in Aceh, IYSA Successfully Held I3O 2023</h1>
            <img class="" src="../../../../assets/img/news/IYSAsuksesdiAceh.WebP" alt="">
            <p>
                The Indonesian Young Scientist Association (IYSA) created a special event in the field of Internet of Things (IoT), namely the Indonesia International IoT Olympiad (I3O) in 2023. This is the second event held by IYSA to find and bring up various innovations from young researchers in the field of IoT that will definitely be useful in the future.
            </p>
            <p>
                IYSA Management Board, Anggraini said, the event is in line with the development of technology in the world today is increasingly rapid, and currently the internet is one of the important things that are always in contact with our lives.IYSA Management Board, Anggraini said, the event is in line with the development of technology in the world today is increasingly rapid, and currently the internet is one of the important things that are always in contact with our lives.
            </p>
            <p>
                The 2nd I3O event in 2023 will be held in Aceh in collaboration with Syiah Kuala Aceh University on June 13-17, 2023. Previously, the first I3O was held in Kudus, Central Java.Anggraini explained, in this 2nd year, I3O was attended by 120 teams divided into 94 teams participating in online competitions and 26 teams participating in offline competitions from 13 countries including Vietnam, Thailand, Iran, Philippines, Malaysia, South Africa, Turkey, Indonesia, Mexico, Bangladesh, Timor Leste, United Arab Emirates and Azerbaijan.
            </p>
            <p>
                On the first day on Tuesday, June 13, 2023, an opening ceremony was held at AAC Dayan Dawood Building, Darussalam Banda Aceh and was attended by all offline and online participants. Online participants attended the opening ceremony directly through the Zoom application or live broadcast on IYSA's Youtube. And continued with a judging session for online participants.Moving on to day 2 on Wednesday 14 June 2023 became a thrilling day for offline participants. Where participants conduct face-to-face judging with the jury."Each team will be judged by two judges, besides that they can also visit other participants' booths," he explained.
            </p>
            <p>
                Furthermore, on the third day on June 15, 2023, a webinar was held with one of the lecturers from Syiah Kuala University, Dr. Ir. Kahlil, S.T., M.Eng. with the theme 'AI used in Science' which was attended by all I3O 2023 participants, both offline and online participants.On the 4th day, it becomes time for self-calming. Participants who register for the Excursion activity are invited to take a walk around various tourist attractions in Aceh. The first destination is to visit the boat or boat over the Lampulo house, then the trip continues to the icon of Aceh province, namely Baiturrahman Mosque, and continued with lunch at Dekgam 2 Restaurant. After that, proceed to the Floating Diesel Power Plant (PLTD) which was a silent witness to the devastating tsunami that hit Aceh 19 years ago.
            </p>
            <p>
                Furthermore, we invited participants to Taman Sari Gunongan, satisfied to take pictures at Taman Sari Gunongan, we invited participants to shop at Pusaka Souvernir, after shopping we continued the journey to Rumah Cut Nyak Dien, one of the heroines from Aceh, satisfied to see history at Rumah Cut Nyak Dien, we invited participants to play water at Lampuuk Beach and ended the trip with dinner together at Cafe Kuala Cangkoi."The judging has been done, we have gained new knowledge through webinars, and we are satisfied walking around, so we have arrived at the most awaited day by all participants, namely the day of the winner announcement for the I3O 2023 event on Saturday, June 17, 2023 in a hybrid manner," said Anggraini
                
            </p>
            <p>
                Anggraini said, there are many prizes given at I3O in 2023. Among them are Gold Medal, Silver Medal, Bronze Medal, IYSA Special Award, MIICA Special Award, Syiah Kuala University Special Award, Bach Khoa Math and Science Special Award, IYSA Semi Grand Award, and IYSA Grand Prize."We would like to thank all participants who have participated in this I3O 2023 event, and congratulations to all winners. Let's develop your best ideas and achieve achievements with IYSA," he said.
            </p>
            
            <p>Source : <a target="_blank"
                    href="https://indonesiadaily.net/menjadi-acara-pertama-di-aceh-iysa-sukses-selenggarakan-i3o-2023/">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->