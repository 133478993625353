<app-navbar-one></app-navbar-one>

<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1> List of Winners 2023</h1>
                    <ul>
                        <li>
                            <a routerLink="/kategori23">Previous Page</a>
                        </li>
                        <li>Event Level</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="low-section">
    <div class="container">
        <div class="section-internal">
            <div>
                <h1>List of Winners</h1>
                <h4 class="mb-3">
                    Choose based on the Level of the Offline event you are
                    participating in
                </h4>
            </div>
            <div
                class="row section-button-wrapper section-dual-button wow fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
            >
                <!-- Start Button -->
                <a
                    href="https://drive.google.com/file/d/1NF3yhsK3ctCEDBhIvfJV0VD8iqNcT6fF/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >University</a
                >
                <a
                    href="https://drive.google.com/file/d/1NUZ9gkaiTyIGy6iVmGvY5Aa2TFmTCxLA/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >Senior High Schools</a
                >
                <a
                    href="https://drive.google.com/file/d/1laIED9_TvqJ0piZf53f4b2Fb5Un-SjxM/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >Junior High School</a
                >
                <a
                    href="https://drive.google.com/file/d/1gCb8a-aIXg-PL1zkmCWQSmUp-NhB3dBt/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >Elementary School</a
                >
                <!-- End Button -->
            </div>
        </div>
    </div>
</section>
