
<app-navbar-one></app-navbar-one>

<!-- Start Page Title Area -->
<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/ListNews">News</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>Undiksha Singaraja Wins Two Golds at I3O Event</h1>
            <img class="" src="../../../../assets/img/news/UndikshaSingaraja.WebP" alt="">
            <p>
                SINGARAJA, NusaBali.com - Universitas Pendidikan Ganesha (Undiksha) Singaraja, Buleleng Regency, Bali, won two gold medals at the Indonesia International IoT Olympiad (I3O) event held by Muria Kudus University, Central Java, May 8-22, 2022.
            </p>
            <p>
                "A total of two student teams won gold medals thanks to innovative work in international Olympic activities involving around 200 teams from 14 countries," said Undiksha Vice Rector for Student Affairs, Alumni, and Public Relations Prof. Dr. I Wayan Suastra MPd, Tuesday (25/5/2022).
            </p>
            <p>
                A said the success of achieving this achievement was greeted with a sense of pride by the team. This achievement is also used as a motivation to take part in other competitions, especially at the international level.His party also gave appreciation for the success of students through their hard work with lecturers who have made Undiksha proud. "We at the leadership give tremendous appreciation to the younger students and lecturers who are always enthusiastic about competing, especially being able to achieve achievements," he said.
            </p>
            <p>
                Suastra also invites other students to always be enthusiastic about competition and achievement. This is also expected to get support from lecturers. "Achievement is not only important for the university, but also for the students themselves. With achievements, it will be more competitive," he said.
            </p>
            <p>
                The Undiksha envoy who won the gold, the first team consisted of Ni Wayan Ayu Kesumawati, Ni Kadek Dwi Utami, Putu Wia Rosita Dewi, Kadek Gita Cahyani, and Ni Luh Gede Kusumasari. All of them are students from the Mathematics Education Study Program. This team presented a work in the form of learning media for deaf students called Fun Math. 
            </p>
            <p>
                This work is a mathematical Pop Up Book integrated with augmented reality technology. Mathematics material in this media is packaged according to the characteristics of deaf students and culture-based (ethnomathematics).
            </p>
            <p>
                The birth of this innovative work, according to Suastra, cannot be separated from the desire to help make it easier for deaf students to learn mathematics. Matter, especially building space that is abstract, can be easily understood. Under the guidance of lecturer I Made Suarsana, S.Pd., M.S, the work won gold medals in the education category and special awards from the Malaysia Young Scientists Organization (MYSO).
            </p>
            <p>
                The second team consisted of I Ketut Adi Darma Yatra from the Mathematics Study Program, Luh Hanny Arsana Putri from the Mathematics Study Program, I Made Bhisma Putra Nugraha from the Informatics Engineering Education Study Program, I Made Kerisna Laksana from the Information Systems Study Program and I Gede Agus Sudiantara from the Elementary School Teacher Education Study Program.
            </p>
            <p>
                This team presented the work "B-Fiks: Interactive Literacy Learning Media To Strengthen The Alpha Generation's Education Of Character During The Post Pandemic Era". This work is a learning media innovation that raises the problem of low literacy in Indonesia.
            </p>
            <p>
                UTim thought about solutions that could be used to improve literacy that could be integrated with online-based learning. Through that, the guidance team of I Putu Pasek Suryawan S.Pd., M.Pd., won a gold medal also in the education category.
            </p>
            
            <p>Source : <a target="_blank"
                    href="https://www.nusabali.com/berita/118002/undiksha-singaraja-raih-dua-emas-pada-ajang-i3o">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->