<div class="navbar-area">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo/logo-i3o.png" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo/logo-i3o.png" alt="logo" />
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Home <i class="fa fa-angle-down"></i
                            ></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >SaaS Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/marketing"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Marketing Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/startup"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Startup Home</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/seo-agency"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >SEO Agency Home</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/about"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >About</a
                            >
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Services <i class="fa fa-angle-down"></i
                            ></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/services"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Services</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/services-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Services Details</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Projects <i class="fa fa-angle-down"></i
                            ></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/projects"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Projects</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/projects-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Projects Details</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Pages <i class="fa fa-angle-down"></i
                            ></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/about"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >About</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/team"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Team</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FAQ</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/pricing"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Pricing</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/login"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Log in</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/signup"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Sign up</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/error"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >404 Error</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/contact"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Contact</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/coming-soon"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Coming Soon</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Shop <i class="fa fa-angle-down"></i
                            ></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/products-list"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Products List</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/products-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Single Products</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/cart"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Cart</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/checkout"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Checkout</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Blog <i class="fa fa-angle-down"></i
                            ></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/blog"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Blog</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Blog Details</a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div class="others-options startup-option">
                        <div class="language-list">
                            <div class="select-box">
                                <select>
                                    <option value="5">Eng</option>
                                    <option value="1">USA</option>
                                    <option value="2">Australia</option>
                                    <option value="0">France</option>
                                </select>
                            </div>
                        </div>

                        <div
                            class="sidebar-menu"
                            data-bs-toggle="modal"
                            data-bs-target="#myModal2"
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
