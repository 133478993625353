<app-navbar-one></app-navbar-one>

<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>List of Winners 2023</h1>
                    <ul>
                        <li>
                            <a routerLink="/lowtahun">Previous Page</a>
                        </li>
                        <li>Event Category</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="low-section">
    <div class="container">
        <div class="section-internal">
            <div>
                <h1>List of Winners</h1>
                <h4 class="mb-3">
                    Choose based on the Category of the event you are
                    participating in
                </h4>
            </div>
            <div
                class="section-button-wrapper section-dual-button wow fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
            >
                <!-- Start Button -->
                <a
                    href="https://drive.google.com/file/d/1ARJJmIyG8b062LK69uyuFT-h1QXnzkvD/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >Special Award</a
                >
                <a routerLink="/jenjangofl23" class="btn-low m-1 col m-3"
                    >I3O OFFLINE</a
                >
                <a routerLink="/jenjangonl23" class="btn-low m-1 col m-3"
                    >I3O ONLINE</a
                >
                <!-- End Button -->
            </div>
        </div>
    </div>
</section>
