<app-navbar-one></app-navbar-one>

<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>News</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start News Area -->
<section id="News" class="News-area uk-section">
    <div class="container">
        <h1 class="text-center uk-text-bold mt-5"> News 2023</h1>
        <div class="uk-grid ">
            <div class="card uk-item">
                <a class="" href="News1">
                    <img src="../../../../assets/img/news/UNSOED.WebP" alt="">
                    <div class="card-body">
                        <h5>UNSOED Delegation Wins I3O International IoT Olympiad 2023 Medals</h5>
                        <p>
                            Another proud achievement was presented by students of Jenderal Soedirman University (UNSOED) at the international level. This time at a prestigious event smelling of industrial revolution 4.0, namely the Indonesia International IoT Olympiad (I3O) 2023.
                        </p>
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="News2">
                    <img src="../../../../assets/img/news/13NegaraDiI3O.WebP" alt="">
                    <div class="card-body">
                        <h5>A total of 13 countries participated in the IoT International Olympiad</h5>
                        <p>
                            A total of 13 countries took part in the Indonesia International Internet of Things Olympiad 2023 which will be held from 13 – 17 June 2023 at Syiah Kuala University (USK)."USK feels proud and grateful for being trusted by the International Youth Scientist Association (IYSA) to host an international event like this.
                        </p>
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="News3">
                    <img src="../../../../assets/img/news/IYSAsuksesdiAceh.WebP" alt="">
                    <div class="card-body">
                        <h5>Being the First Event in Aceh, IYSA Successfully Held I3O 2023</h5>
                        <p>
                            The Indonesian Young Scientist Association (IYSA) created a special event in the field of Internet of Things (IoT), namely the Indonesia International IoT Olympiad (I3O) in 2023. This is the second event held by IYSA to find and bring up various innovations from young researchers in the field of IoT that will definitely be useful in the future.
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="container">
        <h1 class="text-center uk-text-bold mt-5"> News 2022</h1>
        <div class="uk-grid ">
            <div class="card uk-item">
                <a class="" href="News4">
                    <img src="../../../../assets/img/news/UMKtuanrumah.WebP" alt="">
                    <div class="card-body">
                        <h5>UMK to Host Indonesia International IoT Olympiad 2022</h5>
                        <p>UMK - Muria Kudus University is hosting the Indonesia International IoT Olympiad 2022. This international competition organized by the Indonesian Young Scientist Association (IYSA) collaborated with UMK to succeed the Indonesia International IoT Olympiad (I3O) event which was held on Saturday (21/5/22) at the Auditorium of Muria Kudus University.</p>
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="News5">
                    <img src="../../../../assets/img/news/UMK.WebP" alt="">
                    <div class="card-body">
                        <h5>14 countries participate in Indonesia International IoT Olympiad 2022 in Kudus</h5>
                        <p>
                            Kudus (ANTARA) - A total of 172 teams from 14 countries participated in the Indonesia International Internet of Think (IoT) Olympiad 2022, which is a competition for creativity in various fields of science, especially science and technology.
                        </p>
                    </div>
                </a>
            </div>
            <div class="card uk-item">
                <a class="" href="News6">
                    <img src="../../../../assets/img/news/UndikshaSingaraja.WebP" alt="">
                    <div class="card-body">
                        <h5>Undiksha Singaraja Wins Two Golds at I3O Event</h5>
                        <p>SINGARAJA, NusaBali.com - Universitas Pendidikan Ganesha (Undiksha) Singaraja, Buleleng Regency, Bali, won two gold medals at the Indonesia International IoT Olympiad (I3O) event held by Muria Kudus University, Central Java, May 8-22, 2022.
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
<!-- End News Area -->
