import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jenjangonl23',
  templateUrl: './jenjangonl23.component.html',
  styleUrls: ['./jenjangonl23.component.scss']
})
export class Jenjangonl23Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Implementasi lain yang mungkin Anda miliki di sini
  }
}
