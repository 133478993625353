<app-navbar-one></app-navbar-one>

<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Start Contact Area -->
<div class="container">
    <div class="accordion" id="faqAccordion">
        <h1 class="text-center mt-5">After register</h1>
        <div class="mb-4">
            <div class="accordion-item" *ngFor="let item of faqItems1; let i = index">
                <h2 class="accordion-header ">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#faqItem' + i">
                        {{ item.question }}
                    </button>
                </h2>
                <div [id]="'faqItem' + i" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        {{ item.answer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion" id="faqAccordion">
        <h1 class="text-center mt-5">During the Event</h1>
        <div class="mb-4">
            <div class="accordion-item" *ngFor="let item of faqItems2; let i = index">
                <h2 class="accordion-header ">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#faqItem2' + i">
                        {{ item.question }}
                    </button>
                </h2>
                <div [id]="'faqItem2' + i" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        {{ item.answer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion" id="faqAccordion">
        <h1 class="text-center mt-5">After Event</h1>
        <div class="mb-4">
            <div class="accordion-item" *ngFor="let item of faqItems3; let i = index">
                <h2 class="accordion-header ">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#faqItem3' + i">
                        {{ item.question }}
                    </button>
                </h2>
                <div [id]="'faqItem3' + i" class="accordion-collapse collapse">
                    <div class="accordion-body">
                        {{ item.answer }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Contact Area -->