
<app-navbar-one></app-navbar-one>

<!-- Start Page Title Area -->
<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <ul>
                        <li>
                            <a routerLink="/ListNews">News</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start News Area -->
<section class="News-Page">
    <div class="conntainer">
        <div class="News-Container">
            <h1>A total of 13 countries participated in the IoT International Olympiad</h1>
            <img class="" src="../../../../assets/img/news/13NegaraDiI3O.WebP" alt="">
            <p>
                A total of 13 countries took part in the Indonesia International Internet of Things Olympiad 2023 which will be held from 13 – 17 June 2023 at Syiah Kuala University (USK)."USK feels proud and grateful for being trusted by the International Youth Scientist Association (IYSA) to host an international event like this," said USK Vice Rector for Student Affairs and Entrepreneurship Prof. Mustanir in Darussalam, Banda Aceh, Tuesday.
            </p>
            <p>
                The 13 countries are Vietnam, Thailand, Iran, the Philippines, Malaysia, South Africa, Turkey, Indonesia, Mexico, Bangladesh, Timor Leste, the United Arab Emirates and Azerbaijan.He explained that the Olympic participants were divided into 75 teams that participated in the competition online and 25 other participants were present offline at USK."Of course this is an honor for USK, because prestigious competencies like this are a place for young thinkers to showcase their innovative ideas and creativity in the Internet of Things (IoT) realm," he said.
            </p>
            <p>
                According to him, the IoT Olympiad is not just a competition but a form of international collaboration to support young talents in the IoT world. All participants involved in this competition demonstrated the power of connectivity and diversity of ideas that transcend borders. Therefore, Mustanir considered the Indonesia International IoT Olympiad 2023 a good opportunity for all participants to establish communication, exchange ideas or perspectives to enrich understanding of IoT.
            </p>
            <p>
                "We hope this event can spark the spirit of innovation in each of us. Let's embrace the limitless potential of this IoT, and strive to create a world where technology serves as a catalyst for positive change". President of Indonesian Youth Scientist Association Deni Irawan expressed his appreciation and gratitude for USK's willingness to host the international event."This Olympics is a form of shared commitment globally in supporting IoT, where this cutting-edge technology has revolutionized many things in the industrial world. We also hope that this competition can foster the spirit of innovation in the IoT field for the realization of a better global life," said Deni. 
            </p>
            <p>
            </p>
            <p>Source : <a target="_blank"
                    href="https://www.smartcityindo.com/2023/06/sebanyak-13-negara-ikuti-olimpiade.html?m=1">Click
                    Here
                </a>
            </p>
        </div>
    </div>
</section>
<!-- End News Area -->