<app-navbar-one></app-navbar-one>

<div class="page-title-area margin-atas-page">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1> List of Winners 2023</h1>
                    <ul>
                        <li>
                            <a routerLink="/kategori23">Previous Page</a>
                        </li>
                        <li>Event Level</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="low-section">
    <div class="container">
        <div class="section-internal">
            <div>
                <h1>List of Winners</h1>
                <h4 class="mb-3">
                    Choose based on the Level of the Online event you are
                    participating in
                </h4>
            </div>
            <div
                class="row section-button-wrapper section-dual-button wow fadeInUp"
                data-wow-delay="0.3s"
                data-wow-duration="1500ms"
            >
                <!-- Start Button -->
                <a
                    href="https://drive.google.com/file/d/1-4YQ-gKx7nmyokOBS1u9RqpHGN6c5xt6/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >University</a
                >
                <a
                    href="https://drive.google.com/file/d/1E2taK5IJveE8AaL7s7FsyHjihK3qjWSA/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >Senior High Schools</a
                >
                <a
                    href="https://drive.google.com/file/d/1-8t1Ad3pS5uNS_SlReH7jGshu1ou08PH/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >Junior High School</a
                >
                <a
                    href="https://drive.google.com/file/d/1thWt3M9hnEY0DnbGktgYN3V7i6rOsB6_/view?usp=sharing"
                    target="_blank"
                    class="btn-low m-1 col m-3"
                    >Elementary School</a
                >
                <!-- End Button -->
            </div>
        </div>
    </div>
</section>
